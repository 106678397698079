













































































































































































import { Inject } from '@cds/common';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { BonusService } from '@/services/bonus-service';
import bonuseUpload from './compoennts/bonuseUpload.vue';

@Component({
  components: {
    bonuseUpload
  }
})
export default class wechatImageText extends Vue {
  public form: any = {
    size: 10,
    current: 1,
    total: 0
  };
  public formInfo: any = {
    percentageIntervalList: [
      {
        percentageIntervalStart: '',
        percentageIntervalEnd: '',
        coefficient:''
      }
    ],
    brand: '',
   // coefficient: '',
    promotionGrid: '',
    radioBrand: ''
  };

  public labelPosition: String = 'top';
  public title: string = '';
  public title2: string = '';
  private tableData: any[] = [];
  public failReason: any[] = [];
  public loading: Boolean = true;
  public loading2: Boolean = true;
  private tableData2: any[] = [];
  public kindshow: Boolean = false;
  public dialogVisible: boolean = false;
  public dialogDetails: boolean = false;
  private currentRow: any = null;
  public rules: any = {
    promotionGrid: [
      { required: true, message: '请输入产品线', trigger: ['change', 'blur'] }],
    brand: [
      { required: true, message: '请输入计算产品', trigger: ['change', 'blur'] }],
    radioBrand: [
      { required: true, message: '请输入标志产品', trigger: ['change', 'blur'] }],
    coefficient: [
      { required: true, message: '请输入系数', trigger: ['change', 'blur'] }],
    // percentageIntervalList: [
    //   { required: true, message: '请输入百分比区间开始--百分比区间结束', trigger: ['change', 'blur'] }],
    percentageIntervalStart: [
      { required: true, message: '请输入百分比区间开始', trigger: ['change', 'blur'] }],
    percentageIntervalEnd: [
      { required: true, message: '请输入百分比区间结束', trigger: ['change', 'blur'] }]

  };

  @Inject(BonusService) private bonusService!: BonusService;

  public async created(): Promise<void> {
    await this.getList();
  }

  public search() {
    this.form.current = 1;
    this.getList();
  }

  //分页相关
  public handleSizeChange(size: number) {
    this.form.size = size;
    this.getList();
  }

  public handleCurrentChange(current: number) {
    this.form.current = current;
    this.getList();
  }

  //获取数据源
  public async getList() {
    this.loading = true;
    const res = await this.bonusService.productInfoCoefficientList(this.form);
    this.loading = false;
    let data: any = res.records;
    this.tableData = data;
    this.form.total = res.total;
    this.form.size = res.size;
    this.form.current = res.current;
  }

  //新增
  public async handAdd() {
    this.title2 = '添加特定产品线系数配置';
      this.formInfo = {
        percentageIntervalList : [{
          percentageIntervalStart: '',
          percentageIntervalEnd: '',
          coefficient:''
        }],
        brand: '',
       // coefficient: '',
        promotionGrid: '',
        radioBrand: '',
        type:1
      }
    this.dialogDetails = true;

   // console.log(this.formInfo, 'this.formInfo');
  }

  //下载
  public async downLoadAll() {
    this.loading = true;
    var data_ = {
      type: '18'
    };
    const res = await this.bonusService.downLoadAll(data_, '特定产品线系数表.xlsx');
    this.loading = false;
  }

  //保存
  public submitForm(): void {
    const addRef = (this.$refs.formInfo as any);
   // console.log(this.formInfo, 'formInfo');
    addRef.validate(async (valid: any) => {
      if (valid) {
        if (this.formInfo.id == undefined) {
          const res = await this.bonusService.addProductInfoCoefficient(this.formInfo);
          if (res) {
            this.$message({
              message: '添加成功',
              type: 'success'
            });
          }
          else{
            this.$message({
              message: '添加失败',
              type: 'error'
            });
          }
        } else {
          delete this.formInfo.type;
          const res = await this.bonusService.addProductInfoCoefficient(this.formInfo);
          if (res) {
            this.$message({
              message: '修改成功',
              type: 'success'
            });
          }
          else{
            this.$message({
              message: '修改失败',
              type: 'error'
            });
          }
        }
        this.dialogDetails = false;
        this.dialogVisible = false;
        this.getList();
      } else {
        console.log('error submit!!');
        return false;
      }
    });
  }

  //特定产品线详情或者修改
  public async updateOrDetails(row: any, type: any) {
    //this.formInfo = JSON.parse(JSON.stringify(row));
    const res = await this.bonusService.getProductInfoCoefficient(row);
    if (type == 'details') {
      this.title = '特定产品线详情';
      this.dialogVisible = true;
      this.loading2 = false;
      this.tableData2 = res;
    } else if (type == 'update') {
     // console.log(row, 'row');
      this.title2 = '修改特定产品线系数配置';
      this.dialogDetails = true;
      this.formInfo.percentageIntervalList = res.map((item: any) => {
        return {
          percentageIntervalStart: item.percentageIntervalStart,
          percentageIntervalEnd: item.percentageIntervalEnd,
          coefficient:item.coefficient,
          id:item.id
        };
      });
      this.formInfo.brand = row.brand;
      //this.formInfo.coefficient = row.coefficient;
      this.formInfo.promotionGrid = row.promotionGrid;
      this.formInfo.radioBrand = row.radioBrand;
      this.formInfo.id = row.id;
    }
  }

  //特定产品线详删除

  private async handDeleteList(row: any): Promise<void> {
    let param = {
      id: row.id,
      promotionGrid:row.promotionGrid,
      brand:row.brand,
      radioBrand:row.radioBrand
    };
    try {
      await this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      });
      await this.bonusService.deleteProductInfoCoefficient(param);
      this.dialogVisible = false;
      this.getList();
      this.$message({
        message: '删除成功',
        type: 'success'
      });
    } catch (e) {
      this.$message({
        type: 'info',
        message: '已取消删除'
      });
    }
  }


  // 重置
  public reset() {
    this.form = {
      size: 10,
      current: 1,
      total: 0
    };
    this.search();
  }

  removeItem(item: any) {
    const index = this.formInfo.percentageIntervalList.indexOf(item);
    if (index !== -1) {
      this.formInfo.percentageIntervalList.splice(index, 1);
    }
  }

  addItem() {
    this.formInfo.percentageIntervalList.push({
      percentageIntervalStart: '',
      percentageIntervalEnd: '',
      coefficient:''
    });
    // this.formInfo.brand = '';
    // this.formInfo.coefficient = '';
    // this.formInfo.promotionGrid = '';
    // this.formInfo.radioBrand = '';
    // await  this.$nextTick(function(){
    //    this.formInfo.percentageIntervalList = this.formInfo.percentageIntervalList
    //   // this.$set(this.form,'percentageIntervalList',this.formInfo.percentageIntervalList)
    // });

    // setTimeout(() => {
    //   this.formInfo.percentageIntervalList = this.formInfo.percentageIntervalList;
    // }, 300);

   // console.log(this.formInfo.percentageIntervalList, 'this.formInfo.percentageIntervalList');
  }

}
